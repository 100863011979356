// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Col, Grid, Row} from "react-flexbox-grid";
import iqrf_ide_4 from "../../Components/Technology/img/IDE.jpg";
import iqrf_ide_command from "../../Components/Technology/img/IDE-Command.png";
import "../../Components/Technology/Technology.scss";
import pdf_ico from "../../App/extra-icons/pdf_ico.png";
import {Helmet} from "react-helmet";
import i_ico from "../../App/extra-icons/i_ico.png";
import exe_ico from "../../App/extra-icons/exe_ico.png";
import i_zip from "../../App/extra-icons/zip_ico.png";
import {HashLink} from "react-router-hash-link";
/**
 * @class TechnologyIDEScene
 */
class TechnologyIDEScene extends React.Component {

	/**
	 * Constructor
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			showContentSupportedGUI: false,
			showContentGUI: false,
			showContentCommand: false,
			supportedCommand: false,
		};
	}

	/**
	 * Final Render
	 * @returns {*}
	 */
	render() {

		const {
			showContentSupportedGUI,

			supportedCommand,
		} = this.state;

		return (
			<Grid className={"page"}>
				<Helmet>
					<title>IDE - IQRF</title>
				</Helmet>
				<Row className="page__section--bottom-padding">
					<Col xs={12}>
						<h1>IQRF IDE</h1>
						<h2>Integrated development and service environment</h2>
						<p><HashLink to="/technology/ide#ide-with-gui">» Full featured IDE with graphics (GUI)</HashLink><br/>
							 <HashLink to="/technology/ide#ide-command-line">» Command line IDE</HashLink></p>
						<br/>
						<hr/>
						<br/>
						<div className="container--responsive" id="withgui">
							<h2 id={"ide-with-gui"}>IDE with GUI</h2>
							<p><strong>Full featured project oriented IDE with Graphical User Interface</strong></p>
							<br/>
							<div className="text--center">
								<img src={iqrf_ide_4} alt={"IQRF IDE GUI"}/>

							</div>
							<br/>
							<p>Intended for developers requiring <strong>interactive project-oriented</strong> work,
								<strong> complexity</strong> and <strong>sophisticated tools</strong> for design, upload,
								debug, testing, service and maintenance.</p>
						</div>
						{/*{!showContentGUI &&*/}
						{/*<div className="text--center">*/}
						{/*	<br/>*/}
						{/*	<button className="btn" onClick={() => this.setState({showContentGUI: !showContentGUI})}>{t("button.MoreInfo")}</button>*/}
						{/*</div>}*/}
						<div>
							<hr/>
							<h3>Features</h3>
							<ul>
								<li>Environment for creation of IQRF applications</li>
								<li>TR
									<Link to={"/product-detail/ck-usb-04a"}> programmer </Link>
									(uploader), <strong>debugger</strong> and <strong>configuration tool</strong></li>
								<li>RF communication <strong>terminal</strong> and test tool</li>
								<li>IQMESH <strong>network manager</strong> - tools for DPA network management,
									visualization and testing
								</li>
								<li>IQRF <strong>service tools</strong> - CATS (<strong>Configurator</strong>,
									Analyzer, Tester and Scanner)
								</li>
								<li>Tools to <strong>upgrade OS</strong> in TR transceivers, <strong>firmware </strong>
									in IQRF USB devices and other <strong>maintenance</strong> utilities
								</li>
								<li>Tools for <strong>Autonetwork</strong> (automatic building up the network),
									access to <strong>IQRF Repository</strong>, …
								</li>
								<li>…</li>
							</ul>
							<div className={"link"} onClick={() => this.setState({showContentSupportedGUI: !showContentSupportedGUI})}>
								» Devices and platforms supported
								<br/>
								<br/>
							</div>
							{showContentSupportedGUI && (
								<div>
									<hr/>
									<h4>Devices supported:</h4>
									<ul>
										<li>All IQRF <strong>USB</strong>
											<Link to={"/products/development-tools/development-kits"}> development kits </Link>
											(CK-USB-04(A), …)
										</li>
										<li>IQRF
											<Link to={"/products/gateways"}> gateways </Link>
											with USB interface (GW-USB/ETH/GSM/WIFI)
										</li>
									</ul>
									<h4>TR transceivers supported:</h4>
									<ul>
										<li>For TR-5xB, TR-5xD, TR-7xD or higher</li>
										<li>For older TR types (TR-11A, TR-21A, TR-31B and TR-32B) use <Link to={"/technology/ide/ide2"}>IQRF IDE 2</Link></li>
									</ul>
									<h4>Windows supported:</h4>
									<ul>
										<li>Tested for Windows 11, Windows 10, Windows 8.x and Windows 7</li>
										<li>Not tested for virtual machines</li>
									</ul>
									<p>Installation includes also USB drivers and C compiler.</p>
									<hr/>
								</div>
							)}
							<p>Please, always use the <strong>latest IDE version</strong>. The installation process itself takes a few seconds only. All your projects and settings will stay functional. Possible returning to an older version
								is simple as well. All <strong>older proven IDE versions</strong> are available to <Link to={"/support/downloads"}>download</Link> from the <strong>Archive</strong>, section <HashLink to="/support/downloads#iqrf-ide">IQRF IDE</HashLink>   .</p>
							<hr/>
							<p>Tip for the easiest IDE Help usage:
								<ul>
									<li>Click this icon on the IDE toolbar</li>
								</ul>
							</p>
							<Row>
								<Col xs={12} xl={6}>
									<img src="https://api.iqrf.org/static/media/img/web/7b588d20c9ab41c74214c9c54b0a7a87.png" alt="IDE-Help"/>							
									<ul>
										<li>Then click on the item for which you want to view Help</li>
									</ul>
								</Col>
							</Row>	
							
							<hr/>
							<h3>Links and downloads</h3>
							<div className="table--wrapper">
								<table className="table--with-links">
									<tbody>
									<tr>
										<td><Link to="/download/55cf7d37-28f2-44d7-96ef-de6d993d3b03"
											   target="_self"><img alt={"icon"} src={exe_ico}/> IQRF IDE v4.74</Link>
										</td>
									</tr>
									<tr>
										<td><Link to="/download/d6c204df-0368-41d4-8d60-4b7b5b15ee0b"
											   target="_self"><img alt="icon" src={pdf_ico}/> USB drivers installation guide </Link>
											- Step by step instructions and troubleshooting. (Only for advanced users
											not employing the standard IDE installation.)
										</td>
									</tr>
									<tr>
										<td><a href="https://doc.iqrf.org/IQRF-IDE-Release-Notes/" rel="noreferrer noopener" target="_blank"><img alt={"icon"} src={i_ico}/> Release notes</a> – IQRF IDE history</td>
									</tr>
									<tr>
										<td><a href="https://doc.iqrf.org/IQRF-IDE-Help" rel="noreferrer noopener" target="_blank"><img alt={"icon"} src={i_ico}/> IQRF IDE Help</a></td>
									</tr>
									<tr>
										<td><a href="https://youtu.be/LTqeZ1pANKA" target="_blank" rel="noreferrer noopener"><i className="icon-youtube-logo"/> How to start a new project in IQRF IDE</a></td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>

						<br/>
						<hr/>
						<br/>
						<br/>
						<div>
							<h2 id="ide-command-line">Command line IDE</h2>
							<p><strong>TR programmer (uploader) running from the command line (IDE Command)</strong></p>
							<br/>
							<div className="text--center">
								<img src={iqrf_ide_command} alt={"IQRF IDE COMMAND LINE"}/>
							</div>
							<br/>
							<p>Intended for:</p>
							<ul>
								<li><strong>Experienced</strong> developers - to make design process faster and more effective.</li>
								<li><strong>Unskilled</strong> operators - to speed up and secure the manufacturing. Simply <strong>run a batch</strong> file by a single stroke, avoiding an operator's failure.</li>
							</ul>
						</div>
						{/*{!showContentCommand &&*/}
						{/*<div className="text--center">*/}
						{/*	<br/>*/}
						{/*	<button className="btn" onClick={() => this.setState({showContentCommand: !showContentCommand})}>{t("button.MoreInfo")}</button>*/}
						{/*</div>}*/}

						<div>
							<div className={"link"} onClick={() => this.setState({supportedCommand: !supportedCommand})}>
								» Devices and platforms supported
								<br/>
								<br/>
							</div>
							{supportedCommand && (
								<div>
									<hr/>
									<h4>Devices supported:</h4>
									<ul>
										<li><strong>Transceivers</strong> TR-5xB, TR-5xD, TR-7xD or higher</li>
										<li>All IQRF <strong>USB</strong> development kits (CK-USB-04(A), …)</li>
										<li>IQRF gateways with USB interface (GW-USB/ETH/GSM/WIFI)</li>
									</ul>
									<h4>Windows supported:</h4>
									<ul>
										<li>Tested for Windows 11,  Windows 10, Windows 8.x and Windows 7</li>
									</ul>
									<hr/>
								</div>
							)}
							<p>Please, always <strong>use the latest IDE Command</strong> version. The upgrade is very simple - just an unzipping the .ZIP package.</p>
							<h3>Links and downloads</h3>
							<div className="table--wrapper">                    {/* Nefunguje ????? */}
								<table className="table--with-links">
									<tbody>
									<tr>
										<td><Link to="/download/e7103abf-8f8c-4b9e-bebd-90d7bc2395e9"
											   target="_self"><img alt={"icon"} src={i_zip}/> IQRF IDE Command </Link>
											v1.10
										</td>
									</tr>
									<tr>
										<td><Link to="/download/d6c204df-0368-41d4-8d60-4b7b5b15ee0b"
											   target="_self"><img alt="icon" src={pdf_ico}/> USB drivers installation guide </Link>
											- Step by step instructions and troubleshooting. (Only for advanced users
											not employing the standard IDE installation.)
										</td>
									</tr>
									<tr>
										<td><Link to={"/technology/ide/historycommand"}><img alt={"icon"} src={i_ico}/> Release notes</Link> – IQRF IDE Command history</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>

					</Col>
				</Row>
			</Grid>
		);
	}
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(TechnologyIDEScene);
